<template>
  <div>
    <!--    <b-card no-body>-->
    <!--      <b-card-body>-->
    <!--        <b-row>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="4"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              :label="$t('labels.title')"-->
    <!--              label-for="title"-->
    <!--            >-->
    <!--              <b-form-input-->
    <!--                id="title"-->
    <!--                :value="searchFields.real_estates"-->
    <!--                @input="inputChange($event, 'real_estates')"-->
    <!--              />-->
    <!--            </b-form-group>-->
    <!--          </b-col>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="4"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              :label="$t('labels.customer')"-->
    <!--              label-for="customer"-->
    <!--            >-->
    <!--              <b-form-input-->
    <!--                id="customer"-->
    <!--                :value="searchFields.customer"-->
    <!--                @input="inputChange($event, 'customer')"-->
    <!--              />-->
    <!--            </b-form-group>-->
    <!--          </b-col>-->
    <!--        </b-row>-->
    <!--      </b-card-body>-->
    <!--    </b-card>-->
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'comparisons-logs',
      singularName: 'comparisons-logs',
      searchFields: {
        real_estates: null,
        customer: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
}
</script>
